import React, { useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import classNames from "classnames"
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"

import siteLogo from "../../images/billsby-logo.svg"
import SEO from "../../components/seo"

const LifetimeCheckout = () => {
  const stripePromise = loadStripe(
    "pk_test_51H4NevAP8I9LfBKlKPWrbPFtx1FGMBwxY6FpHI7c20PHBVja5BsGpycsDcgtyqG8u4phbECi1fbB2rjkQConcY9N00pdEgCHBi"
  )
  const [amount, setAmount] = useState(300)
  const [price, setPrice] = useState(297)

  const handleClick = async () => {
    try {
      const stripe = await stripePromise

      const session = await axios
        .get(
          "https://billsby-mailgun.onrender.com/api/stripe/create-checkout-session?amount=" +
          amount
        )
        .then(({ data }) => data)
        .catch(err => {
          throw err
        })

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      })

      if (result.error) console.log(result.error)
    } catch (e) {
      console.log(e)
    }
  }

  const handleOnChange = value => {
    setAmount(value)

    if (amount === 100) setPrice(99)

    if (amount === 200) setPrice(198)

    if (amount === 300) setPrice(297)

    if (amount === 400) setPrice(396)

    if (amount === 500) setPrice(495)
  }

  return (
    <div className="lifetime-checkout">
      <SEO
        title="0.4% of revenue share"
        description="The most competitively priced subscription billing software around can process recurring payments for your business."
        url="https://www.billsby.com/lifetime-checkout"
      />
      <div className="header-checkout">
        <div className="container">
          <div className="header-wrapper">
            <Link to="/">
              <img className="logo-orig" src={siteLogo} alt="Logo" />
            </Link>
            <div className="secure-checkout">
              <FontAwesomeIcon icon={faLock} />
              <p>Secure Checkout</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-checkout-box">
        <div className="container">
          <div className="checkout-box-wrapper">
            <h2>Lifetime Deal Checkout</h2>
            <p>
              For every $99 one time fee, add another $100,000 per year of
              revenue.
            </p>
            <div className="slider-range-wrapper">
              <Slider
                min={100}
                max={500}
                step={100}
                value={amount}
                orientation="horizontal"
                tooltip={false}
                // onChange={(value) => setAmount(value)}
                onChange={handleOnChange}
              // onChangeComplete={handleOnChange}
              // onChangeStart={handleOnChange}
              />
              <div
                className={classNames(
                  "custom-tooltip",
                  { "first-value": amount === 100 },
                  { "second-value": amount === 200 },
                  { "third-value": amount === 300 },
                  { "fourth-value": amount === 400 },
                  { "fifth-value": amount === 500 }
                )}
              >
                <p>${amount},000</p>
                <p>Annual Revenue</p>
              </div>
            </div>
            <button
              type="button"
              className="btn-orange"
              role="link"
              onClick={handleClick}
            >
              Checkout for ${price}
            </button>
            <p className="small-text">
              No questions asked 14 day money back guarantee. New customers only
              - incl. free trial customers but excl. currently paying customers.
            </p>
          </div>
        </div>
      </div>
      <div className="footer-checkout">
        <div className="container">
          <p>© Billsby 2021</p>
          <span>Powered by Airfi Networks</span>
        </div>
      </div>
    </div>
  )
}

export default LifetimeCheckout
